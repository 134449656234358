import {  useState } from 'react';
import { isBrowser } from '../helpers/isBrowser';

type t = (val: string | number | Record<string, unknown> | [] ) => void | string

const useLocalStorage = (key: string, initialValue: string) => {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const item = isBrowser() ? window.localStorage.getItem(key) : null;
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			console.error(error);
			return initialValue;
		}
	});

	const setValue = (value: t) => {
		try {
			const valueToStore =
			value instanceof Function ? value(storedValue) : value;
			setStoredValue(valueToStore);
			
			if ( isBrowser() ){
				window.localStorage.setItem(key, JSON.stringify(valueToStore));
			}
		} catch (error) {
			console.error(error);
		}
	};
	
	return [storedValue, setValue];
};

export default useLocalStorage;
